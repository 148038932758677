<template>
  <b-container class="rounded box-shadow-1 d-flex justify-content-center w-75">
    <validation-observer ref="simpleRules">
      <b-card
        class="d-flex w-100 px-0 justify-content-center align-items-center"
      >
        <b-card-text class="h1 mb-1 font-large-1 text-center text-primary">
          Quote Delivery Price?
        </b-card-text>
        <!-- <hr class="border-3 divider-primary"/> -->
        <hr />
        <b-row class="mb-2">
          <b-col class="col-16">
            <b-row>
              <b-col class="col-12 col-lg-6 w-100">
                <b-form-group label="Origin Postcode">
                  <validation-provider
                    #default="{ errors }"
                    name="Postcode Origin"
                    rules="required"
                    class="w-100"
                  >
                    <b-form-input
                      v-model="form.postcode_origin"
                      name="Postcode Origin"
                      type="text"
                      placeholder="From Postcode"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12" lg="6">
                <validation-provider
                  #default="{ errors }"
                  name="Postcode Destination"
                  rules="required"
                  class="w-100"
                >
                  <b-form-group label="Destination Postcode">
                    <b-form-input
                      v-model="form.postcode_destination"
                      type="text"
                      placeholder="Destination Postcode"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <b-row class="items-center">
              <b-col cols="12" lg="6">
                <b-row>
                  <b-col cols="10">
                    <validation-provider
                      #default="{ errors }"
                      name="Shipment"
                      rules="required"
                      class="w-100"
                    >
                      <b-form-group label="Shipment">
                        <select
                          v-model="form.shipment"
                          class="form-control form-select rounded"
                        >
                          <option value="" disabled>Shipment</option>
                          <option
                            v-for="(address, index) in SHIPMENT_TYPE"
                            :key="index"
                            :value="address"
                          >
                            {{ address.label }}
                          </option>
                        </select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col cols="2" class="align-self-center">
                    <feather-icon
                      v-b-tooltip.click.v-dark
                      title="DHL not part of this Quotation due to the DHL price itself"
                      icon="InfoIcon"
                      size="26"
                      class="text-primary toolTip"
                    />
                  </b-col>
                </b-row>
              </b-col>
              <b-col cols="12" lg="6" class="mt-1 align-self-center">
                <b-button
                  variant="gradient-primary"
                  class="w-100 box-shadow-1"
                  @click="onSubmit"
                >
                  <span v-if="!isLoading">Quote</span>
                  <span v-else>
                    <b-spinner style="height: 15px; width: 15px" />
                  </span>
                </b-button>
              </b-col>
            </b-row>
          </b-col>
        </b-row>

        <hr class="mb-2" />
        <!-- Estimated Price/Proceed to Order -->
        <div class="row align-items-center">
          <div class="col-sm-12 col-md-12 col-lg-6">
            <div class="row my-1">
              <div class="col">
                <span class="d-block">ESTIMATED PRICE:</span>
                <span class="d-block">
                  <h1 v-if="!isLoading">
                    {{ $helpers.formatTotal(unitPrice) }}
                  </h1>
                  <b-spinner v-else variant="primary" class="text-center" />
                </span>
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-md-12 col-lg-6">
            <b-button
              variant="gradient-primary"
              class="w-100 box-shadow-1"
              @click="onOrder"
              >Proceed to Order</b-button
            >
          </div>
        </div>
        <div v-if="requested" class="mt-2">
          <span class="h1 text-primary font-medium-3">
            {{
              underCoverage
                ? 'Yay! Delivery to this area is available.'
                : errorMessage
            }}
          </span>
        </div>
      </b-card>
    </validation-observer>
  </b-container>
</template>

<script>
import { VBTooltip } from 'bootstrap-vue';
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
import axios from '@axios';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required } from '@validations';
import { SHIPMENT_TYPE } from '@/common/Constants';

export default {
  components: {
    vSelect,
    VBTooltip,
    ValidationProvider,
    ValidationObserver,
  },

  directives: {
    'b-tooltip': VBTooltip,
  },

  data() {
    return {
      SHIPMENT_TYPE,
      OpenIndicator: {
        render: (createElement) => createElement('span', '🔽'),
      },
      underCoverage: false,
      requested: false,
      errorMessage: '',
      unitPrice: '00.00',
      isLoading: false,
      form: { shipment: '' },
    };
  },
  methods: {
    getOrder() {
      axios;
      this.$http
        .get(`get_order/${this.$route.query.payment_id}`)
        .then((response) => {
          this.isLoading = false;

          // Check the response was a success
          if (response.status === 200) {
            this.orderLabel = response.data.data;
          }
        });
    },
    async onSubmit() {
      this.requested = false;
      this.unitPrice = '00.00';
      this.$refs.simpleRules.validate().then(async (success) => {
        if (success) {
          this.isLoading = true;
          const response = await this.$http.get(
            `quote?postcode_origin=${this.form.postcode_origin}&postcode_destination=${this.form.postcode_destination}&order_type=${this.form.shipment.label}`
          );
          if (!response.data.status) {
            this.underCoverage = response.data.status;
            this.errorMessage = response.data.message;
            this.requested = true;
            this.isLoading = false;
            return;
          }

          this.underCoverage = response.data.status;
          this.unitPrice = response.data.quotation.unit_price;
          this.requested = true;
          this.isLoading = false;
        }
      });
    },
    onOrder() {
      if (this.unitPrice > 0) {
        if (this.form.shipment.value === 'Outlet') {
          this.$router.push('/Outlet-order');
        } else {
          this.$router.push('/singapore-order');
        }
      }
    },
  },
};
</script>

<style lang="css">
.toolTip {
  cursor: pointer;
  background-color: transparent !important;
}

.style-chooser .vs__search {
  padding: 2px 7px !important;
}

.style-chooser .vs__search::placeholder {
  color: #8e8e8e;
}
</style>
