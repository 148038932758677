<template>
  <div>
    <div class="mb-2 font-large-1 h1">
      Greetings! Outlet {{ userData.name }},
    </div>
    <b-card class="p-lg-1 mb-1 mx-n3 rounded-0">
      <div class="row justify-content-center align-items-center">
        <div class="col-xs-10 col-sm-10 col-md-7 col-lg-7">
          <img
            :src="require('@/assets/images/logo/delivery.png')"
            class="img-fluid"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-5 col-lg-5">
          <DeliveryPrice />
        </div>
      </div>
    </b-card>

    <!-- Guideline -->
    <b-row class="mx-1 my-2">
      <h2 class="text-primary font-large-1 d-none d-sm-inline-block">
        Guidelines
      </h2>
    </b-row>

    <b-row>
      <b-col
        v-for="(guidelineItem, index) in guidelineList"
        :key="index"
        cols="12"
        lg="4"
        class="mb-2"
      >
        <Guideline :guideline-item="guidelineItem" />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { getUserData } from '@/auth/utils';
import Guideline from './Guideline.vue';
import DeliveryPrice from './DeliveryPrice.vue';

export default {
  components: {
    DeliveryPrice,
    Guideline,
  },

  data() {
    return {
      guidelineList: [
        {
          label: 'Shipment',
          headers: ['Type of Shipment', 'Order ID'],
          items: [
            ['Outlet', 'F000000'],
            ['B2C', 'D000000'],
            ['B2BPRO', 'B000000'],
            ['DHL', 'DHL ID'],
            ['Singapore', 'S000000'],
          ],
        },
        {
          label: 'Logpoint',
          headers: ['Color', 'Description'],
          items: [
            ['Green', 'Good'],
            ['Orange', 'Running Low'],
            ['Red', 'Empty'],
          ],
        },
        {
          label: 'Client',
          headers: ['Category', 'Description'],
          items: [
            ['Silver', 'Basic'],
            ['Gold', '.............'],
            ['Platinum', 'Premium'],
          ],
        },
      ],
    };
  },

  created() {
    this.userData = getUserData();
  },
};
</script>

<style></style>
